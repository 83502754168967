import React, { Component, Suspense } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Layout from './components/Layout/';
import routes from './routes';

import 'react-toastify/dist/ReactToastify.css';
import './Apps.scss';
import './assets/css/colors/default.css';
import './assets/css/extra.css';
import './assets/css/materialdesignicons.min.css';

function withLayout(WrappedComponent, hasDarkTopBar) {
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar}>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={this.Loader()}>
            <Switch>
              {routes.map((route, idx) =>
                route.isWithoutLayout ? (
                  <Route
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    key={idx}
                  />
                ) : (
                  <Route
                    path={route.path}
                    exact
                    component={withLayout(route.component, route.isTopbarDark)}
                    key={idx}
                  />
                ),
              )}
            </Switch>
          </Suspense>
        </Router>
        <MessengerCustomerChat
          pageId="104666310988626"
          appId="522302457862871"
        />
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default withRouter(App);
