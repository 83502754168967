import React from 'react';

const Sell = React.lazy(() => import('./pages/Sell/index'));
const Expired = React.lazy(() => import('./pages/Expired/index'));
const Automate = React.lazy(() => import('./pages/Automate/index'));
const HomePage = React.lazy(() => import('./pages/HomePage/index'));

const PageAgents = React.lazy(() => import('./pages/Pages/PageAgents'));
const PricingPlan = React.lazy(() =>
  import('./pages/Pages/PricingPlan/PricingPlan'),
);
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));

const Blogs = React.lazy(() => import('./pages/Pages/Blog/BlogList'));
const BlogDetail = React.lazy(() => import('./pages/Pages/Blog/BlogDetail'));

const Payment = React.lazy(() => import('./pages/Pages/Payment/PagePayment'));

//Contact
const PageContactDetail = React.lazy(() =>
  import('./pages/Pages/Contact/PageContactDetail'),
);
//hamt olon
const PageTeam = React.lazy(() => import('./pages/Pages/PageTeam'));
//bidnii tuhai
const PageAboutUs = React.lazy(() => import('./pages/Pages/PageAboutUs'));
//ajliin bair
const PageJobApply = React.lazy(() =>
  import('./pages/Pages/Careers/PageJobApply'),
);

//uilchulgeenii nuhtsul
const PageTerms = React.lazy(() => import('./pages/Pages/Utility/PageTerms'));

//nuutslaliin bodlogo
const PagePrivacy = React.lazy(() =>
  import('./pages/Pages/Utility/PagePrivacy'),
);

//gariin avlaga
const HelpCenterFaqs = React.lazy(() =>
  import('./pages/Pages/HelpCenter/HelpCenterFaqs'),
);
//api zaavar

const routes = [
  //routes without Layout

  //Contact without layout
  {
    path: '/contact',
    component: PageContactDetail,
    isWithoutLayout: true,
    isTopbarDark: true,
  },
  {
    path: '/agents',
    component: PageAgents,
    isWithoutLayout: true,
    isTopbarDark: true,
  },

  //Contact without layout
  {
    path: '/expired',
    component: Expired,
    exact: false,
    isWithoutLayout: true,
    isTopbarDark: true,
  },

  //Email Pages

  //Index root
  { path: '/', component: HomePage, exact: true },
  { path: '/sell', component: Sell, exact: true, isTopbarDark: true },
  { path: '/blogs', component: Blogs, exact: true, isTopbarDark: true },
  {
    path: '/pricing-plan',
    component: PricingPlan,
    isTopbarDark: true,
    exact: true,
  },
  { path: '/automate', component: Automate, exact: true, isTopbarDark: true },
  { path: '/about', component: PageAboutUs, exact: true, isTopbarDark: true },
  {
    path: '/jobapply',
    component: PageJobApply,
    exact: true,
    isTopbarDark: true,
  },
  {
    path: '/helpcenter',
    component: HelpCenterFaqs,
    exact: true,
    isTopbarDark: true,
  },
  { path: '/team', component: PageTeam, exact: true, isTopbarDark: true },
  {
    path: '/pageprivacy',
    component: PagePrivacy,
    exact: true,
    isTopbarDark: true,
  },
  { path: '/pageterms', component: PageTerms, exact: true, isTopbarDark: true },
  {
    path: '/blogs/:id',
    component: BlogDetail,
    exact: true,
    isTopbarDark: true,
  },
  {
    path: '/payment',
    component: Payment,
    exact: true,
    isTopbarDark: true,
  },

  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
