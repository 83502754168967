import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';

const Topbar = React.lazy(() => import('./Topbar'));
const Footer = React.lazy(() => import('./Footer'));

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};
class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <Suspense fallback={Loader()}>
          <Topbar tagline={null} hasDarkTopBar={this.props.hasDarkTopBar} />
          {this.props.children}
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
