import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import kr from './locales/kr.json';
import mn from './locales/mn.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      mn: {
        translation: mn,
      },
      en: {
        translation: en,
      },
      kr: {
        translation: kr,
      },
    },
    fallbackLng: 'mn',
    lng: 'mn',
    debug: false,
    detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
